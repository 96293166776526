import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Build your idea without coding skills!`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/1*QH4RGlNwXUFnJSytytvb6A.jpeg",
        "alt": "Credit: https://www.pexels.com/photo/macbook-pro-92904/"
      }}></img></p>
    <p>{`I don’t remember how many times I have been asked to build the new great “idea” by non-tech founders.`}</p>
    <p>{`Most of the time, they just need someone that could build a `}<u><b>{`Minimum Viable Product (MVP)`}</b></u>{`.`}</p>
    <br />
The problem is that they do not know where to start and usually it is going to cost them ($10000 — $30000). 
You don’t want to start throwing money at this stage.
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/700/1*qHMAoTzOwHmNTMJFbykycw.jpeg",
        "alt": "Pig with Money"
      }}></img></p>
    <p>{`Well, you do not need a developer at all. You can do a lot without writing a single line of code.
It is amazing what is possible with few tools out there.`}</p>
    <br /><br />
    <p>{`I will show you 3 online services for creating your MVP without coding skills or any developer needed.`}</p>
    <br /><br />
    <h2>{`Tool 1: Zapier`}</h2>
    <p>{`The first tool in my toolbox: Zapier.`}</p>
    <p>{`This tool makes my life so much easier every time I need to add integrations to my products.
You can either build these integrations yourself or use a service like Zapier.`}</p>
    <p>{`It allows you to move data between your product and external services (e.g. Dropbox, Gmail, Salesforce, etc…).
It also allows moving data between external services like:`}</p>
    <ul>
      <li parentName="ul">{`moving all your files from Dropbox to Google Drive and vice-versa`}</li>
      <li parentName="ul">{`moving all the invoices from Shopify to Quickbooks`}</li>
      <li parentName="ul">{`add new customers from your website to Salesforce`}</li>
      <li parentName="ul">{`and much more…`}</li>
    </ul>
    <p>{`To better give you an idea, let’s start with a use case.`}</p>
    <p>{`Let’s say that you have an e-commerce site with your favorite platform (e.g. Shopify) which has already buying customers. You need some integrations like:`}</p>
    <ul>
      <li parentName="ul">{`Grab customer’s email address and add it to your email list.`}</li>
      <li parentName="ul">{`Add new customers information in your customer relationship management (CRM).`}</li>
      <li parentName="ul">{`Create a follow-up email ticket in your support system.`}</li>
      <li parentName="ul">{`Send a push notification to your phone when a new order is in.`}</li>
      <li parentName="ul">{`Grab all your invoices and save them in your Google Drive.`}</li>
    </ul>
    <p>{`So many things and lots of development involved!`}</p>
    <h3>{`How does Zapier work?`}</h3>
    <p>{`Zapier has a concept called “Zap” which represents a connection between two apps. Each “Zap” has the concept of “trigger” and “action”.
Every time your trigger is activated, it executes the given action. That simple!`}</p>
    <p>{`Let’s go back to our example.`}</p>
    <br />
We wanted to build this integration:
- “Add new customers information in your customer relationship management (CRM). ”
    <br />
    <br />
    <em><b>What’s a trigger in this case?</b></em>
    <p>{`A trigger could be “A new order from Shopify”.
That means that when there is a new order in Shopify, this trigger will activate.`}</p>
    <p>{`Ok, we have a trigger. What could be an action in this case?`}</p>
    <p>{`An action could be something like “Add the customer info from the new order in Salesforce”.
It will add the customer information from your Shopify order in Salesforce.`}</p>
    <p>{`That’s all. It’s that easy. Two clicks and you can set up one connection.`}</p>
    <p>{`They can connect with more than 700 apps and you can find the complete list of supported apps `}<a parentName="p" {...{
        "href": "http://bit.ly/2ewc5fM"
      }}>{`here`}</a>{`. `}<br />{`
Some example of workflows:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2eYb2qg"
        }}>{`Create MailChimp subscribers from Paypal or Typeform`}</a>{`: Track sales made via PayPal and add the customer’s email address to your email list to Mailchimp`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2ezvNWE"
        }}>{`Create Quickbooks invoices from PayPal`}</a>{`: Create an invoice in QuickBooks from a PayPal transaction.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2dNObeC"
        }}>{`Generate a new ticket in Zendesk for a new order in Shopify`}</a>{`: Follow up with customers on new orders.`}</li>
    </ul>
    <p>{`In this `}<a parentName="p" {...{
        "href": "https://zapier.com/help/create/basics/create-zaps"
      }}>{`link`}</a>{`, you can see how easy it create a new “Zap”.`}</p>
    <h2>{`Tool 2: `}<a parentName="h2" {...{
        "href": "https://bubble.io/"
      }}>{`Bubble.io`}</a></h2>
    <p>{`Bubble is for users who have never written a line of code and don’t want to!`}</p>
    <br />
It is a drag-and-drop platform that will let you build complex sites. You don’t need to write a single line of code.
It is based on the concept of building blocks that you move around. Each block has a given functionality.
There are predefined blocks with lots of functionality or you can create your custom one.
    <br /><br />
The platform has a great layout and always suggest you your next step.
    <br /><br />
The documentation is well done: many videos and tutorial to get you started fast. A full working website in less than 30 minutes.
It is ideal if you don’t have programming experience and you need an MVP of a dynamic site (not a static HTML site).
    <br /><br />
    <p>{`It is ideal if you don’t have programming experience and you need an MVP of a dynamic site (not a static HTML site).`}</p>
    <p>{`There is even a course where they teach how to create a clone of AirBnB using Bubble.`}</p>
    <h3>{`What else can you build with Bubble?`}</h3>
    <br />
Here few examples:
- [Coffee Searcher](http://bit.ly/2ezwAqu): Search favorite coffee places based on your location ([Blog post](http://bit.ly/2eeydJG)).
- [Find a Trainer](http://bit.ly/2dAwrF3): Find personal trainers to help meet your fitness goals on your schedule.
- [Microblogging app](http://bit.ly/2f7jyTb): A simple microblogging app
- [Twitter clone](http://bit.ly/2f7nkMs): This is a clone of the Twitter website. (Read the [blog post](http://bit.ly/2dAxcxE) how they built this)
    <p>{`Ok, Domenico. That’s nice. `}<b>{`Where should I start first?`}</b></p>
    <br /><br />
Follow these [lessons](http://bit.ly/2e2l7gS) from the Bubble site and in few hours you will have a new site up and running.
    <h2>{`Tool 3: `}<a parentName="h2" {...{
        "href": "http://bit.ly/2eezsZv"
      }}>{`Webflow`}</a></h2>
    <p>{`This is actually one of my favorite tools right now for building websites.`}</p>
    <b>It is not for beginners</b>. It is targeting designers and entrepreneurs with some basic HTML / CSS skills.
    <p>{`You will get great websites that will work as you expect without writing one single line of code.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/watch?v=AoTBLxN-Vvs" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`Both static sites and dynamic sites with blogging features.`}</p>
    <p>{`Webflow makes it easier to create smooth user interfaces without much hustle. `}<br />{`
You can add animations to any part of yours site using one from their `}<a parentName="p" {...{
        "href": "http://bit.ly/2eezNvi"
      }}>{`library`}</a>{` or creating your own.`}</p>
    <br /><br />
You can either start using one of their ready templates or start from scratch. 
They have a great initial tutorial for learning the basic and it will not take more than 1–2 hours.
    <p>{`The layout of the editor is like Photoshop; if you have used it before you will feel comfortable with it.`}</p>
    <p>{`After you create your website, you can two possibilities:`}</p>
    <ul>
      <li parentName="ul">
        <em>publish it on their hosting platform.</em>
      </li>
      <li parentName="ul">
        <em>export the code and host it wherever you want.</em>
      </li>
    </ul>
    <p>{`I like that they have this option to export your website if you want to host it yourself.`}</p>
    <p>{`I recommend to try it out and see if it fits your needs. As I said, it is not for complete beginners.
If you don’t have basic skills in HTML and CSS, solutions like `}<a parentName="p" {...{
        "href": "http://bit.ly/2dDhrkZ"
      }}>{`Weebly`}</a>{` or `}<a parentName="p" {...{
        "href": "http://bit.ly/2enOD3D"
      }}>{`Wix`}</a>{` could be a better fit for you.`}</p>
    <br /><br />
Do you want to see what is possible with Webflow? Look at these sites below or explore their [showcase](http://bit.ly/2eYdFs0) of websites.
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2eIzabt"
        }}>{`GeoSpace`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2eJ9Va8"
        }}>{`Wars`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2e2pLeS"
        }}>{`Overtongraphics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bit.ly/2eIyR0p"
        }}>{`History of Animation`}</a></li>
    </ul>
    <p>{`As you can see you can do a lot without a single developer.
Building a site nowadays is super easy and it should not be an excuse for not trying to build your idea.`}</p>
    <p>{`How did you build the first MVP of your product? Leave a comment below!`}</p>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <p>{`Youtube: `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Domenico Solazzo's Channel`}</a></p>
    <p>{`Instagram: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/domenicosolazzo/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Linkedin: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/solazzo/"
      }}>{`solazzo`}</a></p>
    <p>{`Medium: `}<a parentName="p" {...{
        "href": "https://medium.com/@domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Facebook: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/domenicosolazzo.labs/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Twitter: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Snapchat: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Github: `}<a parentName="p" {...{
        "href": "https://github.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Website: `}<a parentName="p" {...{
        "href": "https://www.domenicosolazzo.com"
      }}>{`https://www.domenicosolazzo.com`}</a></p>
    <p><strong parentName="p">{`Hashnode`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://hashnode.com/@domenicosolazzo"
      }}>{`https://hashnode.com/@domenicosolazzo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      